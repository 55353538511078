.banner {
    width: 100%;
    background-color: #161616;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;

    @media only screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }

    .left {
        display: flex;
        align-items: center;
    }
}
