.wrapper {
    // height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        color: white;
        text-align: center;
    }
}
